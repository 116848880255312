<script setup>
import { useModal } from 'vue-final-modal';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import InventoryCustomField from '~/inventory/components/inventory-custom-fields/inventory-custom-field.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import InventoryTransactionExport from '~/inventory/components/inventory-transaction/inventory-transaction-export.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryTransactionEmail from '~/inventory/components/inventory-transaction/inventory-transaction-email.vue';
import { renderAsTextarea } from '~/common/utils/common.utils';
import InventoryValidInvalidStocksPopup from '~/inventory/components/inventory-transaction-form/inventory-invalid-valid-popup.vue';
import { useStockItems } from '~/inventory/composables/inventory-stock-items.composable.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import InventoryUom from '~/inventory/components/inventory-uom/inventory-uom.vue';

const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();
const inventory_store = useInventoryStore();
const columns = [
  {
    header: $t('Name'),
    accessorKey: 'name',
    id: 'name',
    cell: info => info.getValue(),
    size: 250,
  },
  {
    header: $t('Notes'),
    accessorKey: 'description',
    id: 'description',
    cell: info => info.getValue() || '-',
    size: 250,
  },
  {
    header: $t('Quantity'),
    accessorKey: 'quantity',
    id: 'quantity',
    size: 100,
  },
];
const is_loading = ref(false);
const state = reactive({
  is_stock_snapshot_loading: false,
});
const { prepareValidInvalidStocksData } = useStockItems();
const transaction_details = computed(() => inventory_store.history_details);

const organization_cover_image_url = computed(() => {
  return auth_store?.current_organization?.cover_image?.url;
});
const invalid_valid_stocks_popup = useModal({
  component: InventoryValidInvalidStocksPopup,
  attrs: {
    onCancel() {
      invalid_valid_stocks_popup.close();
    },
  },
});

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

function transactionDeleteHandler() {
  patchOptions(
    {
      attrs: {
        header: `${$t('Delete Transaction')} - ${transaction_details.value.number}`,
        content: 'Are you sure you want to delete the transaction?',
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await $services.inventory_adjustments.delete({ id: transaction_details.value.uid });
            delete inventory_store.adjustments_map[transaction_details.value.uid];
            inventory_store.active_transaction_uid = (inventory_store.adjustments?.length && inventory_store.adjustments?.[0].uid) || '';
            closeDeletePopup();
          }
          catch ({ data: error }) {
            const { title, message } = inventory_store.get_error_status(error?.error_code) || {};
            $toast({
              title: title || 'Something went wrong',
              text: message || 'Please try again',
              type: 'error',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

function getCustomField(uid) {
  return inventory_store?.custom_fields_map[uid] || {};
}

async function getDetails() {
  try {
    is_loading.value = true;
    await inventory_store.set_history_details({
      id: inventory_store.active_transaction_uid,
      query: {
        asset: route.params.asset_id,
      },
    });
    is_loading.value = false;
  }
  catch (error) {
    is_loading.value = false;
    logger.error('[DEBUG] inventory-transaction-details.vue::64\n', error);
  }
}

function getAttachments(attachments) {
  return attachments?.map(attachment => ({
    ...attachment,
    file_name: attachment.file_name || attachment.name || attachment.service.key.split('/').pop(),
  }));
}

watch(() => inventory_store.active_transaction_uid, () => getDetails(), { immediate: true });
async function openStockSnapshot(data) {
  try {
    state.is_stock_snapshot_loading = data.uid;
    const response = await $services.inventory_adjustments.get({
      attribute: `items/${data.uid}/stock`,
      id: transaction_details.value?.uid,
    });
    if (response.data?.stock_snapshot?.length) {
      const { invalid_stocks, valid_stocks } = prepareValidInvalidStocksData(response.data?.stock_snapshot);
      invalid_valid_stocks_popup.patchOptions({
        attrs: {
          invalid_stocks,
          valid_stocks,
          workflow: inventory_store.workflows_map[transaction_details.value?.workflow],
          item: inventory_store.items_map[data.uid],
          is_batch_number: inventory_store.items_map[data.uid]?.is_batch_number,
          stock_input: inventory_store.history_details.adjustment_lines_map[data.uid]?.stock_input,
          onClose() {
            invalid_valid_stocks_popup.close();
          },
        },
      });
      state.is_stock_snapshot_loading = null;
      invalid_valid_stocks_popup.open();
    }
    else { state.is_stock_snapshot_loading = null; }
  }
  catch (err) {
    logger.error('🚀 ~ openStockSnapshot ~ err:', err);
    state.is_stock_snapshot_loading = null;
  }
}

function getLocationDetails({ uid, type }) {
  return inventory_store.get_location_details({ uid, type });
}
</script>

<template>
  <HawkLoader v-if="is_loading" />
  <div v-else class="grid gap-6 p-6">
    <div class="flex items-center justify-between">
      <div class="flex items-center pb-2 gap-3">
        <div class="text-lg font-semibold">
          {{ transaction_details?.number }}
        </div>
        <HawkBadge v-if="transaction_details?.status === 'draft'" type="dark" color="orange">
          {{ $t('Draft') }}
        </HawkBadge>
      </div>
      <div class="flex gap-2">
        <HawkButton
          v-if="transaction_details?.permissions?.update && route?.params?.asset_id" icon type="text"
          @click="router.push({
            name: 'inventory-transaction-form',
            params: {
              ...route.params,
              workflow_id: transaction_details?.workflow,
              transaction_id: transaction_details.uid,
            },
          })"
        >
          <IconHawkPencilOne />
        </HawkButton>
        <HawkButton
          v-if="transaction_details?.permissions?.destroy" icon type="text"
          @click="transactionDeleteHandler"
        >
          <IconHawkTrashFour />
        </HawkButton>
        <InventoryTransactionEmail v-if="transaction_details?.status !== 'draft'" />
        <InventoryTransactionExport v-if="transaction_details?.status !== 'draft'" :transaction="transaction_details" />
      </div>
    </div>
    <div class="flex justify-between">
      <div>
        <img v-if="organization_cover_image_url?.length" :src="organization_cover_image_url" alt="org logo" class="object-contain w-24 h-10">
        <div v-else>
          <img src="../../../../assets/logos/taskmapper-full.svg" alt="TaskMapper" width="133">
        </div>
      </div>
      <div class="text-sm grid gap-2 text-right">
        <div v-if="transaction_details?.workflow?.length" class="text-lg font-semibold">
          {{ inventory_store.workflows_map[transaction_details?.workflow]?.name }}
        </div>
        <div>{{ transaction_details?.number }} </div>
        <div>{{ $date(transaction_details?.date, 'DD MMMM YYYY h:mm A') }}</div>
        <div>
          <span>{{ $t('Created by') }}: </span>
          <HawkMembers :members="transaction_details?.created_by" type="label" :has_avatar="false" name_classes="!font-normal" :name_truncate_length="20" />
        </div>
      </div>
    </div>
    <div class="flex items-start justify-between gap-10">
      <div class="text-sm grid gap-2">
        <div class="text-lg font-semibold">
          {{ inventory_store.workflows_map[transaction_details?.workflow]?.stock_operation === 'no_op' ? $t('Location') : $t('From') }}
        </div>
        <div>
          <div class="break-all">{{ getLocationDetails(transaction_details?.from_stock)?.name || '-' }}</div>
          <div class="text-xs text-gray-600" v-html="renderAsTextarea(getLocationDetails(transaction_details?.from_stock)?.description)" />
        </div>
      </div>
      <div v-if="inventory_store.workflows_map[transaction_details?.workflow]?.stock_operation !== 'no_op'" class="text-sm grid gap-2 text-right">
        <div class="text-lg font-semibold">
          {{ $t('To') }}
        </div>
        <div>
          <div class="break-all">
            {{ getLocationDetails(transaction_details?.to_stock)?.name }}
          </div>
          <div v-if="transaction_details?.to_stock.type === 'asset'" class="text-xs text-gray-600">
            <p v-if="getLocationDetails(transaction_details?.to_stock)?.code || getLocationDetails(transaction_details?.to_stock)?.address?.name">
              <span>
                {{ getLocationDetails(transaction_details?.to_stock)?.code ? `#${getLocationDetails(transaction_details?.to_stock)?.code}` : '' }}
              </span>
              <span v-if="getLocationDetails(transaction_details?.to_stock)?.code && getLocationDetails(transaction_details?.to_stock)?.address?.name"> , </span>
              <span class="font-normal">
                <HawkText :content="getLocationDetails(transaction_details?.to_stock)?.address?.name" :length="64" />
              </span>
            </p>
          </div>
          <div v-else class="text-xs text-gray-600" v-html="renderAsTextarea(getLocationDetails(transaction_details?.to_stock)?.description)" />
        </div>
      </div>
    </div>
    <div id="transaction-stock">
      <TableWrapperVue v-if="transaction_details?.adjustment_line_item_quantity?.length" :height="500" container_class="border-0">
        <HawkTable
          :data="transaction_details.adjustment_line_item_quantity"
          :columns="columns"
          is_gapless
          disable_resize
          :show_menu_header="false"
        >
          <template #name="{ data }">
            <div>
              <div v-if="data.row.original?.number" class="text-xs text-gray-500">
                #{{ data.row.original?.number }}
              </div>
              <div class="break-all font-medium">
                {{ data.getValue() }}
              </div>
            </div>
          </template>
          <template #quantity="{ data }">
            {{ data.getValue() }} <InventoryUom v-if="data.row.original?.uom" :uom="data.row.original?.uom" is_symbol />
            <HawkButton
              v-if="inventory_store.items_map[data.row.original.uid]?.is_serial_number" v-tippy="'View stock'" icon
              type="text"
              :loading="state.is_stock_snapshot_loading === data.row.original?.uid" class="ml-2" @click="openStockSnapshot(data.row.original)"
            >
              <IconHawkInfoCircle class="h-4 w-4" />
            </HawkButton>
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
    <div v-if="transaction_details?.attachments?.length">
      <div class="font-semibold mb-3 text-sm">
        {{ $t('Attachments') }} ({{ transaction_details?.attachments?.length }})
      </div>
      <HawkAttachmentsGrid
        :items="getAttachments(transaction_details?.attachments)"
        :can_delete="false"
        :enable_description="true"
        :show_delete="false"
      />
    </div>
    <div>
      <div
        v-for="custom_field in transaction_details?.custom_fields"
        :key="custom_field.uid"
        class="text-sm mb-6"
      >
        <div class="shrink-0 text-gray-700 font-semibold">
          {{ getCustomField(custom_field.uid)?.name || 'NA' }}
        </div>
        <InventoryCustomField :value="custom_field.value" :field="getCustomField(custom_field.uid)" />
      </div>
      <div v-if="transaction_details?.remarks" class="text-sm mb-6">
        <div class="shrink-0 text-gray-700 font-semibold">
          {{ $t('Remarks') }}
        </div>
        <div v-html="renderAsTextarea(transaction_details?.remarks)" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#transaction-stock {
  table {
    th {
      font-weight: 700;
      background-color: #667085;
      color: #fff;
      height: 44px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>
