<script setup>
import { keyBy } from 'lodash-es';
import { Validator } from '@vueform/vueform';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';
import Rules from '~/forms/components/form-workflows/sidebar/conditional-block/rule-fields.vue';

const props = defineProps({
  rule: {
    type: Object,
    default: () => ({
      name: 'New Condition',
      description: '',
      rules: [],
      logic: { type: 'and' },
    }),
  },
  step_number: {
    type: Number,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update', 'save', 'delete', 'invalid', 'close', 'toast']);
const form_workflow_store = useFormWorkflowStore();
const form$ = ref(null);
const columns = ref({
  lg: {
    container: 12,
    label: 3,
    wrapper: 12,
  },
});

const $t = inject('$t');

const rule_form = ref([]);
const rules_updated = ref(0);

const fields_options = computed(() => {
  return form_workflow_store.get_fields(props.step_number, [], ['default', 'tablev2']).filter(field => !(['attachment', 'check_item', 'auto_number', 'multi_text'].includes(field.type) || ['info', 'formula'].includes(field.properties?.type)));
});
const get_rules = computed(() => {
  return rule_form.value.rules;
});
const rules_invalid = ref(false);

function goBack(type) {
  if (form$.value?.invalid && type !== 'close')
    emit('toast', { text: 'Please fill required fields', type: 'error' });

  else if (props.rule?.uid)
    emit('close');
  else
    emit('delete');
}

const breadcrumb_items = ref(
  [
    {
      uid: '1',
      label: $t('Conditional Block'),
    },
    { uid: '2', label: !props.rule.uid ? $t('New condition') : $t('Edit condition') },
  ],
);

function breadcrumbClick(e) {
  if (e.uid === '1')
    goBack('close');
}

function saveRule() {
  if (props.rule.uid)
    emit('save', { ...rule_form.value, uid: props.rule.uid });
  emit('save', rule_form.value);
}

function init() {
  rule_form.value = { ...props.rule, rules: Object.values(props.rule.rules || {}) };
}

/**
 * Check if rule fields are from same table or not
 * @param updated_rule_field_id {String}
 * @returns {Boolean} True if no error, False to throw error
 */
function checkIfSameTableField(updated_rule_field_id) {
  let selected_table_section_id = null;
  const field_map = keyBy(fields_options.value, 'uid');
  if (field_map[updated_rule_field_id].section_type !== 'tablev2')
    return true;

  for (const rule of rule_form.value.rules)
    if (field_map[rule.fact].section_type === 'tablev2') {
      if (selected_table_section_id && selected_table_section_id !== field_map[updated_rule_field_id].section_id)
        return false;
      selected_table_section_id = field_map[rule.fact].section_id;
    }

  return true;
}

function updateRules(rules) {
  rule_form.value.rules = rules;

  // trigger validation for rule fields
  form$.value.el$('rules').resetValidators();
  form$.value.el$('rules').validate();
}

const same_table_field = class extends Validator {
  get message() {
    return $t('The fields selected should be from the same table');
  }

  check(value) {
    return checkIfSameTableField(value);
  }
};

init();
</script>

<template>
  <Vueform
    ref="form$" v-model="rule_form" :sync="true" size="sm" :display-errors="false"
    :messages="{ required: $t('This field is required') }"
    :endpoint="saveRule"
  >
    <div class="col-span-12">
      <div class="flex justify-between items-start mb-10">
        <div class="w-80">
          <HawkBreadcrumbs
            :items="breadcrumb_items"
            @crumbClicked="breadcrumbClick"
          />
          <div class="text-lg font-semibold text-gray-900">
            {{ rule_form.name }}
          </div>
        </div>
        <HawkButton type="plain" class="pointer-events-auto" @click="goBack('close')">
          <IconHawkXClose class="text-gray-500" />
        </HawkButton>
      </div>
      <TextElement :focused="true" name="name" :label="$t('Name')" class="mb-5" rules="required" :readonly="is_disabled" :columns="columns" />
      <hr class="mb-5">
      <div class="flex items-center">
        <div class="text-[14px] font-semibold">
          {{ $t('Rules') }}
        </div>
      </div>
      <Rules
        has_blank_not_blank_operators
        :rules="get_rules"
        :fields="fields_options"
        :tree_config_options="{
          validation_rules: [same_table_field],
        }"
        @invalid="($event) => (rules_invalid = $event)"
        @updateRules="updateRules"
      />

      <div class="text-sm font-semibold mb-4">
        {{ $t('Execute when') }}
      </div>
      <ObjectElement name="logic">
        <RadiogroupElement
          name="type"
          :items="[
            { value: 'and', label: $t('All conditions are met (AND)') },
            { value: 'or', label: $t('Any of the conditions are met (OR)') },
          ]"
          :add-classes="{
            RadiogroupRadio: {
              container: 'mb-2',
            },
          }"
          class="mb-5"
        />
      </ObjectElement>
      <hr class="mb-5">
      <div v-if="!is_disabled" class="flex items-center" :class="rule.uid ? 'justify-between' : 'justify-end'">
        <HawkButton v-if="rule.uid" type="link" color="error" @click="$emit('delete')">
          {{ $t('Delete') }}
        </HawkButton>
        <div class="flex justify-end">
          <ButtonElement
            :button-label="$t('Cancel')"
            class="mr-3"
            :secondary="true"
            @click="goBack('close')"
          />
          <ButtonElement
            :button-label="$t('Save')"
            :submits="true"
          />
        </div>
      </div>
    </div>
  </Vueform>
</template>
