<script setup>
import { useRoute } from 'vue-router';
import { uniq } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  name: {
    type: String,
    default: 'value',
  },
  representation: {
    type: String,
    required: true,
  },
  operator: {
    type: String,
    required: true,
  },
  field: {
    type: Object,
    required: {},
  },
  field_name: {
    type: String,
    required: true,
  },
  value: {
    require: true,
  },
});
const emit = defineEmits(['change']);

const $t = inject('$t');
const use_field_uid = inject('use-field-uid', false);
const route = useRoute();
const common_store = useCommonStore();

const getRules = computed(() => {
  const rules = ['required'];
  if (props.representation === 'number')
    rules.push('numeric');
  return rules;
});

const getItems = computed(() => {
  return props.field.config.map((f) => {
    return {
      name: f.name,
      value: use_field_uid && f.uid ? f.uid : f.name,
    };
  });
});

function getCustomMembers() {
  if (props.field_name === 'users')
    return {};
  const members = [];
  const teams = [];

  const should_extract_member = props.field.config.allowed_members?.extract_member;

  for (const member_data of props.field.config.allowed_members.members)
    if (member_data.member) {
      members.push(member_data.uid);
    }
    else if (should_extract_member && !member_data.member) {
      const team_users = common_store.get_team(member_data.uid).users;
      teams.push(...team_users);
    }
    else {
      teams.push(member_data.uid);
    }
  const custom_options = uniq([...members, ...teams]);
  const has_custom_options = !!props.field.config.allowed_members?.members.length;

  return (has_custom_options ? { custom_options, has_custom_options } : {});
}

function onChange(val) {
  emit('change', val);
}
</script>

<template>
  <template v-if="operator === 'blankNotBlank'">
    <RadiogroupElement
      view="tabs"
      :name="name"
      class="px-2"
      rules="required"
      :items="[
        { value: false, label: 'Set' },
        { value: true, label: 'Not set' }
      ]"
    /> 
   </template>
  <div v-else>
    <div v-if="representation === 'number' && operator === 'between'">
      <ListElement
        :name="name"
        :controls="{ add: false, remove: false, sort: false }"
        :add-classes="{
          ListElement: {
            list: '!flex',
          },
        }"
        @change="onChange"
      >
        <template #default="{ index }">
          <TextElement
            :name="index"
            :placeholder="index ? 'Max' : 'Min'"
            class="ml-2"
            input-type="number"
            :rules="getRules"
            autocomplete="off"
          />
        </template>
      </ListElement>
      <div v-if="!value?.includes(null) && (+value?.[0] >= +value?.[1])" class="text-[13px] text-red-600 ml-2.5">
        Invalid min and max input range
      </div>
    </div>
    <TextElement
      v-else-if="['text', 'number', 'long_text', 'phone_number'].includes(representation)"
      :name="name"
      :rules="getRules"
      :placeholder="$t('Enter value')"
      @change="onChange"
    />
    <hawk-assignee-input
      v-if="['users', 'member'].includes(props.field_name) && operator"
      :asset_id="route.params.asset_id"
      :multi="!['equal', 'notEqual'].includes(operator)"
      :truncate_tag_length="8"
      :options="{
        name,
        'has_teams': props.field_name === 'member',
        'placeholder': $t('Select assignees'),
        'object': false,
        'can-clear': false,
        'rules': getRules,
      }"
      v-bind="getCustomMembers()"
      @change="onChange"
    />
    <template v-else>
      <SelectElement
        v-if="['dropdown', 'label_dropdown'].includes(representation) && operator && ['equal', 'notEqual', 'contains', 'doesNotContain'].includes(operator)"
        :name="name"
        :rules="getRules"
        :items="getItems"
        :can-clear="false"
        :native="false"
        :placeholder="$t('Select')"
        label-prop="name"
        value-prop="value"
        @change="onChange"
      />
      <MultiselectElement
        v-if="['dropdown', 'label_dropdown'].includes(representation) && (operator && !['equal', 'notEqual', 'contains', 'doesNotContain'].includes(operator))"
        :name="name"
        :rules="getRules"
        :items="getItems"
        :native="false"
        :hide-selected="false"
        :placeholder="$t('Select')"
        :close-on-select="false"
        label-prop="name"
        value-prop="value"
        @change="onChange"
      />
    </template>
    <DateTimeElement
      v-if="representation === 'date_time'"
      :key="`${field?.uid}_${operator}`"
      :name="name"
      :columns="columns"
      :rules="getRules"
      :options="{
        'v-model': value,
        'range': !operator.endsWith('Than'),
        'format': field?.config?.type === 'time' ? 'h:mm a' : `dd-MMM-yyyy${field?.config?.type === 'datetime' ? ' h:mm a' : ''}`,
        'placeholder': $t('Select date'),
        'enableTimePicker': field?.config?.type === 'datetime',
        'time-picker': field?.config?.type === 'time',
        'is-24': false,
        ...(field?.config?.type === 'time' ? { 'model-type': 'HH:mm' } : { utc: true }),
      }"
      @update:model-value="onChange"
    />
    <RadiogroupElement
      v-if="representation === 'attachment'"
      :name="name"
      :items="[
        { label: $t('True'), value: true },
        { label: $t('False'), value: false },
      ]"
      :add-classes="{
        RadiogroupRadio: {
          container: 'mr-5 flex !items-start mt-2 text-[14px]',
        },
      }"
      :remove-class="{
        wrapper: ['flex-col'],
      }"
      @change="onChange"
    />
  </div>
</template>
