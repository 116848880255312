import AuthService from './auth.service';
import CommonService from './common.service';

const SYSTEM_MODEL_API_HOST = import.meta.env.VITE_APP_SYSTEM_MODEL_API_HOST;
const TERRA_API_HOST = import.meta.env.VITE_APP_TERRA_API_HOST;
const THERM_API_HOST = import.meta.env.VITE_APP_THERM_API_HOST;
const VAULT_API_HOST = import.meta.env.VITE_APP_VAULT_API_HOST;
const SENSEHAWK_HOST = import.meta.env.VITE_APP_SENSEHAWK_HOST;
const SENSEHAWK_HOST_V2 = import.meta.env.VITE_APP_SENSEHAWK_V2_HOST;

const CORE_API_HOST = import.meta.env.VITE_APP_CORE_API_HOST;
const hosts = {
  core: CORE_API_HOST,
  therm: THERM_API_HOST,
  terra: TERRA_API_HOST,
  vault: `${SENSEHAWK_HOST_V2}/dms`,
  forms: VAULT_API_HOST,
  dms: `${SENSEHAWK_HOST_V2}/dms`,
  system_model: SYSTEM_MODEL_API_HOST,
  work: `${SENSEHAWK_HOST}/work_api`,
};
export default {
  auth: AuthService,
  common: CommonService,
  integrations: {
    url: 'integrations/details',

    methods: {
      authorize_integration: req => ({
        url: `integrations/auth/${req.integration_type}/authorize`,
        method: 'GET',
      }),
    },
  },
  extras: {
    url: 'extras-singapore',
    baseURL: 'https://api.sensehawk.com',
    methods: {},
  },
  documents: {
    url: 'dms/documents',
    baseURL: SENSEHAWK_HOST_V2,
    methods: {},
  },
  files: {
    url: 'files',
    methods: {},
  },
  folders: {
    url: 'folders',
    methods: {},
  },
  organizations: {
    url: 'organizations',
    methods: {
      invite_users: req => ({
        url: `organizations/${req.organization_id}/invite_users`,
        method: 'POST',
        data: req.body,
      }),
      generate_upload_url: req => ({
        url: 'generateposturl',
        method: 'POST',
        data: req.body,
      }),
      setup_sample_project: req => ({
        url: 'setup-sample-project',
        method: 'POST',
        data: req.body,
      }),
    },
    // baseEndPoint: "organizations",
    // generateUrl: "generateposturl"
  },

  users: {
    url: 'users',
    methods: {
      get_solarlabs_token: req => ({
        url: 'auth/solarlabs/get-token',
        method: 'POST',
        data: req?.body || {},
        toast: req.toast,
      }),
      get_eanalytix_token: req => ({
        url: 'auth/eanalytix/get-token',
        method: 'POST',
        data: req?.body || {},
        toast: req.toast,
      }),
      get_invites: req => ({
        url: `organizations/${req.organization_id}/invitations`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      resend_invite: req => ({
        url: `organizations/${req.organization_id}/resend_emails/`,
        method: 'POST',
        data: req.body,
        ...(req.headers && { headers: req.headers }),
      }),
      revoke_invite: req => ({
        url: `organizations/${req.organization_id}/cancel_invites/`,
        method: 'POST',
        data: req.body,
        ...(req.headers && { headers: req.headers }),
      }),
      change_password: req => ({
        url: `users/${req.id}/credentials/change_password`,
        method: 'PUT',
        data: req.body,
      }),
      notifications: req => ({
        url: `users/${req.id}/notifications`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      generate_presigned_url: req => ({
        url: '/me/profile/generate/post',
        data: req.body,
        method: 'POST',
      }),
      save_profile_picture: req => ({
        url: 'me/display-picture',
        data: req.body,
        method: 'POST',
      }),
      get_activities: req => ({
        url: `activities/feeds/user:${req.id}/`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      update_activity: req => ({
        url: 'activities/feeds',
        method: 'PATCH',
        data: req.body,
      }),
      get_activities_count: req => ({
        url: `activities/feeds/user:${req.user_id}/unread?rule=$feed&type=notification`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
    },
  },
  assets: {
    url: 'assets',
    methods: {},
  },
  teams: {
    url: 'teams',
    methods: {
      duplicate: req => ({
        url: `teams/${req.id}/duplicate`,
        method: 'POST',
        data: req.body,
      }),
    },
  },
  roles: {
    url: 'roles',
    methods: {
      duplicate: req => ({
        url: `roles/${req.id}/duplicate`,
        method: 'POST',
        data: req.body,
      }),
    },
  },
  fields: {
    url: 'fields',
    methods: {},
  },
  fieldvalues: {
    url: 'fieldvalues',
    methods: {},
  },
  tasks_v2: {
    url: 'tasks',
    baseURL: SENSEHAWK_HOST_V2,
    methods: {},
  },
  tasks: {
    url: 'tasks',
    methods: {
      load_from_template: req => ({
        url: 'tasks/action/load-template/',
        method: 'POST',
        data: req.body,
      }),
      save_template: req => ({
        url: 'tasks/action/save-template/',
        method: 'POST',
        data: req.body,
      }),
      duplicate: req => ({
        url: 'tasks/action/duplicate',
        method: 'POST',
        data: req.body,
      }),
      get_task_attachments: req => ({
        url: `tasks/${req.id}/attachments/`,
        method: 'GET',
        data: req.body,
      }),

      set_task_attachments: req => ({
        url: `tasks/${req.id}/attachments/`,
        method: 'POST',
        data: req.body,
      }),
      update_task_attachments: req => ({
        url: `tasks/${req.id}/attachments/`,
        method: 'PATCH',
        data: req.body,
      }),
      delete_task_attachments: req => ({
        url: `tasks/${req.id}/attachments/${req.attachment_id}/`,
        method: 'DELETE',
      }),
      set_checklist_attachments: req => ({
        url: `tasks/${req.id}/checklists/${req.checklist_id}/items/${req.item_id}/attachments`,
        method: 'POST',
        data: req.body,
      }),
      delete_checklist_attachments: req => ({
        url: `tasks/${req.id}/checklists/${req.checklist_id}/items/${req.item_id}/attachments/${req.attachment_id}/`,
        method: 'DELETE',
      }),
      set_comment_attachments: req => ({
        url: `tasks/${req.id}/comments/${req.comment_id}/attachments/`,
        method: 'POST',
        data: req.body,
      }),
      delete_comment_attachments: req => ({
        url: `tasks/${req.id}/comments/${req.comment_id}/attachments/${req.attachment_id}/`,
        method: 'DELETE',
      }),
      // Checklist
      create_checklist: req => ({
        url: `tasks/${req.id}/checklists/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'POST',
      }),
      update_checklists: req => ({
        url: `tasks/${req.id}/checklists/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'PATCH',
      }),
      update_checklists_template: req => ({
        url: `tasks/${req.id}/checklists/template/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'POST',
      }),
      load_multiple_checklists: req => ({
        url: 'checklists/template',
        data: req.body,
        method: 'POST',
      }),
      delete_checklist: req => ({
        url: `tasks/${req.id}/checklists/${req.checklist_id}`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'DELETE',
      }),
      generate_csv: req => ({
        url: 'tasks/generate/csv',
        data: req.body,
        method: 'POST',
      }),
      get_graph: req => ({
        url: 'reports/new',
        data: req.body,
        method: 'POST',
      }),
      get_tasks_by_status: req => ({
        url: 'tasks/status',
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'GET',
      }),
      get_custom_report: req => ({
        url: 'tasks/reports/export-pdf',
        data: req.body,
        method: 'POST',
        baseURL: SENSEHAWK_HOST_V2,
        toast: false,
      }),
      // Reminders
      get_reminders: req => ({
        url: `tasks/${req.id}/reminders/`,
        ...(req.query && { params: req.query }),
        data: req.body,
      }),
      create_reminder: req => ({
        url: `tasks/${req.id}/reminders/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'POST',
      }),
      update_reminder: req => ({
        url: `tasks/${req.id}/reminders/`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_reminder: req => ({
        url: `tasks/${req.id}/reminders/${req.reminder_id}`,
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'DELETE',
      }),
    },
  },
  checklists: {
    url: 'checklists',
    methods: {
      get_attachments: req => ({
        url: `checklists/${req.checklist_id}/attachments/`,
        method: 'GET',
      }),
      add_attachments: req => ({
        url: `checklists/${req.checklist_id}/items/${req.item_id}/attachments/`,
        data: req.body,
        method: 'POST',
      }),
      delete_attachments: req => ({
        url: `checklists/${req.checklist_id}/items/${req.item_id}/attachments/${req.attachment_id}`,
        data: req.body,
        method: 'DELETE',
      }),
    },
  },
  forms: {
    url: 'forms',
    baseURL: SENSEHAWK_HOST_V2,
    methods: {
      approval: req => ({
        url: 'approval-flow/hook/action',
        ...(req.query && { params: req.query }),
        data: req.body,
        method: 'POST',
      }),
      get_graph: req => ({
        baseURL: SENSEHAWK_HOST_V2,
        url: 'forms/reports',
        data: req.body,
        method: 'POST',
        toast: false,
      }),
      get_custom_report: req => ({
        url: 'forms/reports/export-pdf',
        data: req.body,
        method: 'POST',
        baseURL: SENSEHAWK_HOST_V2,
      }),
      get_submission_report: req => ({
        url: 'forms/submissions/responses/xlsx',
        data: req.body,
        responseType: 'blob',
        method: 'POST',
        baseURL: SENSEHAWK_HOST_V2,
      }),
      get_kanban_data: req => ({
        url: 'forms/kanban-view',
        data: req.body,
        method: 'POST',
        baseURL: SENSEHAWK_HOST_V2,
      }),
      get_attachments: req => ({
        url: 'forms/attachments',
        data: req.body,
        method: 'POST',
        baseURL: SENSEHAWK_HOST_V2,
        // responseType: 'blob',
      }),
      get_field_filters_list: req => ({
        url: `forms/templates/${req.id}/fields-filters`,
        method: 'GET',
        baseURL: SENSEHAWK_HOST_V2,
      }),
    },
  },
  categories: {
    url: 'categories',
    methods: {},
  },
  sm_templates: {
    url: 'templates',
    baseURL: SYSTEM_MODEL_API_HOST,
    methods: {},
  },
  sm_categories: {
    url: 'categories',
    baseURL: SYSTEM_MODEL_API_HOST,
    methods: {},
  },
  sm_components: {
    url: 'components',
    baseURL: SYSTEM_MODEL_API_HOST,
    methods: {
      import_csv: (req) => {
        return {
          baseURL: SYSTEM_MODEL_API_HOST,
          url: `csv/plan/${req.plan_id}/component/${req.component_id}/import-csv/`,
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'post',
        };
      },
      add_layout: (req) => {
        return {
          url: `components/${req.uid}/add/layout`,
          baseURL: SYSTEM_MODEL_API_HOST,
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'post',
        };
      },
      add_alignment: (req) => {
        return {
          url: `components/${req.uid}/add/alignment`,
          baseURL: SYSTEM_MODEL_API_HOST,
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'post',
        };
      },
      get_children: (req) => {
        return {
          url: `components/${req.parent_id}/get-children/`,
          baseURL: SYSTEM_MODEL_API_HOST,
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'get',
        };
      },
    },
  },
  sm_fieldvalues: {
    url: 'fieldvalues',
    baseURL: SYSTEM_MODEL_API_HOST,
    methods: {},
  },
  sm_fields: {
    url: 'fields',
    baseURL: SYSTEM_MODEL_API_HOST,
    methods: {},
  },
  sm_instances: {
    url: 'instances',
    baseURL: SYSTEM_MODEL_API_HOST,
    methods: {
      post_aliases: req => ({
        url: `csv/template/${req.template}/alias`,
        baseURL: SYSTEM_MODEL_API_HOST,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'post',
      }),
      get_asset_instances: req => ({
        url: `instances/asset/${req.asset_id}`,
        method: 'GET',
        baseURL: SYSTEM_MODEL_API_HOST,
      }),
      get_element_hierarchy: req => ({
        url: `/element/${req.id}/hierarchy`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        baseURL: hosts[req.stage.toLocaleLowerCase()],
        method: 'GET',
        toast: req?.toast,
      }),
      get_element_for_instance: req => ({
        url: `/elements/instance/${req.instance_uid}`,
        baseURL: hosts[req.stage.toLocaleLowerCase()],
        method: 'GET',
      }),
      generate_instances: (req) => {
        return {
          url: '/instances/materials/',
          baseURL: SYSTEM_MODEL_API_HOST,
          data: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          method: 'POST',
        };
      },
    },
  },
  inventory: {
    url: 'inventory',
    methods: {},
  },
  inventory_unified_resources: {
    url: 'inventory/unified-resources',
    methods: {
      get_unified_resources: req => ({
        url: 'inventory/unified-resources',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
    },
  },
  inventory_items: {
    url: 'inventory/items',
    methods: {
      get_items_list_by_uids: req => ({
        url: 'inventory/items/item-lists',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      delete_item: req => ({
        url: `inventory/items/${req.id}`,
        method: 'DELETE',
      }),
      get_stock_units: req => ({
        url: 'inventory/stock-units',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'GET',
        toast: req?.toast,
        signal: req?.signal,
      }),
      get_stock_units_details: req => ({
        url: 'inventory/stock-units/get-stock-unit',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      validate_stock_units: req => ({
        url: 'inventory/stock-units/validate-stock-units',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      validate_stock_units_v2: req => ({
        url: 'inventory/stock-units/stocks-validate',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      update_stock_unit: req => ({
        url: `inventory/stock-units/${req.id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
        toast: req?.toast,
        signal: req?.signal,
      }),
      stock_item_aggregate: req => ({
        url: `inventory/items/${req.id}/stocks-aggregates`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
      }),
      update_stock_item_aggregate: req => ({
        url: `inventory/items/${req.id}/stocks-updates`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
      }),
      get_stock_history: req => ({
        url: 'inventory/stock-histories',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'GET',
        toast: req?.toast,
        signal: req?.signal,
      }),
      validate_serial_numbers: req => ({
        url: 'inventory/stock-units/validate-serial-numbers',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
        signal: req?.signal,
      }),
      register_items_in_asset: req => ({
        url: `inventory/items/register/assets/${req.asset_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        toast: req?.toast,
        method: 'POST',
      }),
      register_items_in_warehouse: req => ({
        url: `inventory/items/register/warehouses/${req.warehouse_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
      }),
    },
  },
  inventory_adjustments: {
    url: 'inventory/adjustments',
    methods: {
      send_emails: req => ({
        url: `inventory/adjustments/${req.id}/email-send`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
        toast: req?.toast,
      }),
      export_transaction: req => ({
        url: `inventory/adjustments/${req.id}/export-transaction`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'GET',
        toast: req?.toast,
      }),
    },
  },
  inventory_uom: {
    url: 'inventory/measurement-units',
    methods: {},
  },
  inventory_workflows: {
    url: 'inventory/item-workflows',
    methods: {
      get_acl_workflows: (req) => {
        return {
          url: 'inventory/item-workflows/acl',
          ...(req?.query && { params: req.query }),
          ...(req?.headers && { headers: req.headers }),
          method: 'GET',
          toast: req?.toast,
        };
      },
      initialize_inventory: (req) => {
        return {
          url: 'inventory/initialize-inventory',
          ...(req?.query && { params: req.query }),
          ...(req?.headers && { headers: req.headers }),
          data: req?.body,
          method: 'POST',
          toast: req?.toast,
        };
      },
      generate_transaction_number: (req) => {
        return {
          url: 'inventory/generate-transaction-number',
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
          toast: req?.toast,
        };
      },
      update_workflow: (req) => {
        return {
          url: `inventory/item-workflows/${req.id}`,
          ...(req?.query && { params: req.query }),
          ...(req?.headers && { headers: req.headers }),
          data: req.body,
          method: 'PATCH',
          toast: req?.toast,
        };
      },
    },
  },
  inventory_categories: {
    url: 'inventory/categories',
    methods: {},
  },
  inventory_item_types: {
    url: 'inventory/categories',
    methods: {
      update_item_type: req => ({
        url: `inventory/categories/${req.id}`,
        ...(req?.query && { params: req.query }),
        ...(req?.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_item_type: req => ({
        url: `inventory/categories/${req.id}`,
        method: 'DELETE',
      }),
    },
  },
  inventory_warehouse_types: {
    url: 'inventory/warehouse-types',
    methods: {
      update_warehouse_type: req => ({
        url: `inventory/warehouse-types/${req.id}`,
        ...(req?.query && { params: req.query }),
        ...(req?.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_warehouse_type: req => ({
        url: `inventory/warehouse-types/${req.id}`,
        method: 'DELETE',
      }),
    },
  },
  inventory_stock_statuses: {
    url: 'inventory/item-statuses',
    methods: {
      update_stock_status: req => ({
        url: `inventory/item-statuses/${req.id}`,
        ...(req?.query && { params: req.query }),
        ...(req?.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_stock_status: req => ({
        url: `inventory/item-statuses/${req.id}`,
        method: 'DELETE',
      }),
    },
  },
  inventory_item_statuses: {
    url: 'inventory/item-statuses',
    methods: {},
  },
  inventory_vendors: {
    url: 'inventory/vendors',
    methods: {
      update_vendor: req => ({
        url: `inventory/vendors/${req.id}`,
        ...(req?.query && { params: req.query }),
        ...(req?.headers && { headers: req.headers }),
        data: req.body,
        method: 'PATCH',
      }),
      delete_vendor: req => ({
        url: `inventory/vendors/${req.id}`,
        method: 'DELETE',
      }),
    },
  },
  inventory_stock_in_other_warehouses: {
    url: 'inventory/procurement',
    methods: {},
  },
  inventory_warehouses: {
    url: 'inventory/warehouses',
    methods: {
      get_procurement: req => ({
        url: `inventory/warehouses/${req.id}/procurement`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      set_procurement: req => ({
        url: `inventory/warehouses/${req.id}/procurement`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
      }),
      get_procurement_v2: req => generateAssetScopeRequest(req, `warehouse/${req.id}/procurement`, 'GET', SENSEHAWK_HOST, { prefix_url: 'inventory/', asset_key: 'asset' }),
      set_procurement_v2: req => generateAssetScopeRequest(req, `warehouse/${req.id}/procurement`, 'POST', SENSEHAWK_HOST, { prefix_url: 'inventory/', asset_key: 'asset' }),
      update_procurement_instances: req => generateAssetScopeRequest(req, `warehouse/${req.id}/procurements-association`, 'POST', SENSEHAWK_HOST, { prefix_url: 'inventory/', asset_key: 'asset' }),
      set_procurement_ordered_qty_v2: req => generateAssetScopeRequest(req, 'update-ordered-qty', 'POST', SENSEHAWK_HOST, { prefix_url: 'inventory/', asset_key: 'asset' }),
      get_stock_in_other_warehouses: req => ({
        url: `inventory/warehouses/${req.id}/items/${req.item_id}/warehouse-procurements`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        toast: req?.toast,
      }),
      get_asset_procurement: req => generateAssetScopeRequest(req, 'procurement', 'GET', SENSEHAWK_HOST, { prefix_url: 'inventory/', asset_key: 'asset' }),
    },
  },
  inventory_metrics: {
    url: 'inventory/metrics',
    methods: {},
  },
  inventory_custom_fields: {
    url: 'inventory/custom-fields',
    methods: {},
  },
  inventory_reports: {
    url: 'inventory/reports',
    methods: {
      get_adjustment_lines: req => ({
        url: 'inventory/adjustment-lines',
        method: 'GET',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        toast: req?.toast,
      }),
      export_stock_units: req => ({
        url: 'inventory/export-stock-units',
        method: 'GET',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        extraOptions: req?.extraOptions,
        toast: req?.toast,
        signal: req.signal,
      }),
      export_transaction_report: req => ({
        url: 'inventory/export-transaction-report',
        method: 'GET',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        extraOptions: req?.extraOptions,
        toast: req?.toast,
        signal: req.signal,
      }),
      inventory_dynamic_report: req => ({
        url: 'inventory/dynamic-report',
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
      }),
    },
  },
  inventory_advance_shipments: {
    url: 'inventory/advance-shipments',
    methods: {
      get_shipment_procurement: req => ({
        url: 'inventory/warehouses/shipment-procurement',
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        toast: req?.toast,
      }),
      create_adjustment: req => ({
        url: `inventory/advance-shipments/${req.id}/create-adjustment`,
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        toast: req?.toast,
      }),
    },
  },
  project_management: {
    url: 'project-management',
    methods: {
      get_graph: req => ({
        url: 'project-management/reports/new',
        data: req.body,
        method: 'POST',
        toast: false,
      }),
    },
  },
  lns: {
    url: 'lns',
    methods: {},
  },
  dashboard: {
    url: 'reports',
    methods: {
      get_custom_report: req => ({
        url: 'reports/custom-report',
        data: req.body,
        method: 'POST',
        baseURL: SENSEHAWK_HOST_V2,
      }),
      get_reports: req => ({
        url: 'assets/reports/',
        data: req.body,
        method: 'POST',
      }),
    },
  },
  permissions: {
    url: 'permissions',
    methods: {},
  },
  features: {
    url: 'features',
    baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
    methods: {
      associate_workflow: req => ({
        url: `features/container/${req.container_id}/workflow/${req.id}`,
        data: req.body,
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
      }),
      reset_workflow_progress: req => ({
        url: `container/${req.container_id}/workflow-progress-reset/`,
        data: req.body,
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        toast: req?.toast,
      }),
      update_workflow_progress: req => ({
        url: `features/container/${req.container_id}/workflow-progress`,
        data: req.body,
        method: 'POST',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
      }),
      get_reference_vectors: req => ({
        url: `features/asset/${req.asset_id}/reference-vectors/`,
        baseURL: TERRA_API_HOST,
        method: 'POST',
        data: req.body,
      }),
      get_feature_properties: req => ({
        url: 'features/properties',
        baseURL: TERRA_API_HOST,
        method: 'POST',
        data: req.body,
      }),
      get_feature_properties_operations: req => ({
        url: 'features/properties/operations',
        baseURL: TERRA_API_HOST,
        method: 'POST',
        data: req.body,
      }),
    },
  },
  feature_types: {
    url: 'feature-types',
    baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
    methods: {
      move: req => ({
        url: 'feature-types/move',
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
        method: 'post',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
    },
  },
  feature_type_groups: {
    url: 'feature-type-groups',
    baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
    methods: {
      assign_to_view: req => ({
        url: `container-views/${req.id}/ftg/add/`,
        method: 'post',
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
    },
  },
  terra: {
    url: '',
    baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
    methods: {
      get_progress_updates_metrics: req => ({
        url: `container/${req.container_id}/metrics/progress-updates`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,

      }),
      get_range_analytics_progress_history_metrics: req => ({
        url: `container/${req.container_id}/metrics/progress-history`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
        method: 'post',
      }),
      get_container_schedules: req => ({
        url: `project-management/schedules/${req.schedule_id}/containers/${req.container_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      create_project_details: req => ({
        url: `projects/containers/${req.container_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'post',
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
      }),
      update_project_details: req => ({
        url: `projects/${req.project_id}/containers/${req.container_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'patch',
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
      }),
      delete_project_details: req => ({
        url: `projects/${req.project_id}/containers/${req.container_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'delete',
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
      }),
      get_terra_project_details: req => ({
        url: `groups/${req.group_id}/projects/${req.project_id}/`,
        baseURL: import.meta.env.VITE_APP_CORE_API_HOST,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        toast: req.toast,
        method: 'get',
      }),
      getExtraPropsData: (req = {}) => ({
        url: `csv/container/${req.container_id}/generate-csv`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body || {},
        method: req.method || 'get',
      }),
      getExtraPropsDataSave: (req = {}) => ({
        url: `csv/container/${req.container_id}/import-csv`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: 'post',
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
      }),
      share: req => ({
        url: req.url,
        baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        method: req.method,
      }),
    },
  },
  therm: {
    url: '',
    baseURL: import.meta.env.VITE_APP_THERM_API_HOST,
    methods: {
      get_elements: req => ({
        url: 'elements/feature/',
        method: 'post',
        baseURL: import.meta.env.VITE_APP_THERM_API_HOST,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      get_graph: req => ({
        url: 'metrics/reports',
        baseURL: import.meta.env.VITE_APP_THERM_API_HOST,
        data: req.body,
        method: 'POST',
      }),
    },
  },
  terra_view_service: {
    url: 'container-views',
    baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
    methods: {
      get_elements: req => ({
        url: 'elements/feature/',
        method: 'post',
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      }),
      export_progress_data: (req = {}) => ({
        url: 'progress-data',
        method: 'POST',
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
      }),
      get_graph: req => ({
        url: 'reports',
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
        method: 'POST',
      }),
      get_activities_table: req => ({
        url: 'reports/new',
        baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
        data: req.body,
        method: 'POST',
      }),
    },
  },
  terra_workflow: {
    url: 'workflows',
    baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
    methods: {},
  },
  terra_workflow_service: {
    url: 'workflows',
    baseURL: import.meta.env.VITE_APP_TERRA_API_HOST,
    methods: {},
  },
  tags_service: {
    url: 'tags',
    methods: {},
  },
  plans: {
    url: 'work_api/drawings',
    methods: {},
  },
  annotations_service: {
    url: 'annotations',
    methods: {},
  },
  gis_service: {
    url: '',
    baseURL: import.meta.env.VITE_APP_GIS_API_HOST,
    methods: {
      volume_base_height: (req) => {
        return ({
          url: 'volume/base-height/',
          baseURL: import.meta.env.VITE_APP_GIS_API_HOST,
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      volume_triangulated: (req) => {
        return ({
          url: 'volume/triangulated/',
          baseURL: import.meta.env.VITE_APP_GIS_API_HOST,
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      volume_flat_minimum: (req) => {
        return ({
          url: 'volume/flat-minimum/',
          baseURL: import.meta.env.VITE_APP_GIS_API_HOST,
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      volume_surface_to_surface: (req) => {
        return ({
          url: 'volume/surface-to-surface/',
          baseURL: import.meta.env.VITE_APP_GIS_API_HOST,
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      // Elevation
      elevation_line: (req) => {
        return ({
          url: 'elevation/line/',
          baseURL: import.meta.env.VITE_APP_GIS_API_HOST,
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
      elevation_point: (req) => {
        return ({
          url: 'elevation/point/',
          baseURL: import.meta.env.VITE_APP_GIS_API_HOST,
          data: req.body,
          method: 'post',
          headers: req.headers,
        });
      },
    },
  },
  search: {
    url: 'search',
    methods: {},
  },
  notifications: {
    url: 'notifications',
    methods: {},
  },
  custom_views: {
    url: 'views',
    methods: {},
  },
  cnc_template: {
    url: 'templates',
    baseURL: `${import.meta.env.VITE_APP_SENSEHAWK_HOST}/cnc`,
    methods: {},
  },
  cnc_reports: {
    url: 'reports',
    baseURL: `${import.meta.env.VITE_APP_SENSEHAWK_HOST}/cnc`,
    methods: {},
    // attribute: `/{{report_uid}}/blocks/{{block_uid}}/sections/{{section_uid}}`,
  },
  dms_transmittals: {
    url: 'dms/transmittals',
    baseURL: SENSEHAWK_HOST_V2,
    methods: {},
  },
  dms: {
    url: 'dms',
    baseURL: SENSEHAWK_HOST_V2,
    methods: {
      get_graph: req => ({
        url: 'dms/reports',
        baseURL: SENSEHAWK_HOST_V2,
        data: req.body,
        method: 'POST',
      }),
    },
  },

  // Account settings v2
  users_v2: {
    url: 'users',
    baseURL: SENSEHAWK_HOST_V2,
    methods: {
      get: req => generateAssetScopeRequest(req, `users/${req.id}`, 'get'),
      post: req => generateAssetScopeRequest(req, 'users', 'post'),
      patch: req => generateAssetScopeRequest(req, `users/${req.id}`, 'patch'),
      asset_admins_patch: req => generateAssetScopeRequest(req, 'users/asset-admins', 'patch'),
      global_admins_patch: req => generateAssetScopeRequest(req, 'users/global-admins', 'patch', SENSEHAWK_HOST_V2),
    },
  },
  teams_v2: {
    url: 'teams',
    baseURL: SENSEHAWK_HOST_V2,
    methods: {
      get: req => generateAssetScopeRequest(req, `teams/${req.id}`, 'get'),
      post: req => generateAssetScopeRequest(req, 'teams', 'post'),
      patch: req => generateAssetScopeRequest(req, `teams/${req.id}`, 'patch'),
    },
  },
  roles_v2: {
    url: 'roles',
    baseURL: SENSEHAWK_HOST_V2,
    methods: {
      get: req => generateAssetScopeRequest(req, `roles/${req.id}`, 'get'),
      post: req => generateAssetScopeRequest(req, 'roles', 'post'),
      patch: req => generateAssetScopeRequest(req, `roles/${req.id}`, 'patch'),
    },
  },
  permissions_v2: {
    url: 'permissions',
    baseURL: SENSEHAWK_HOST_V2,
    methods: {},
  },
};

function generateAssetScopeRequest(req, endpoint, method, baseURL = SENSEHAWK_HOST_V2, options = {}) {
  const prefix_url = options?.prefix_url || '';
  const asset_key = options?.asset_key || 'assets';
  const asset_path = req.asset_id ? `${asset_key}/${req.asset_id}/` : '';
  const url = prefix_url + asset_path + endpoint;

  return {
    url,
    method,
    data: req.body,
    baseURL,
    ...(req.query && { params: req.query }),
    ...(req.headers && { headers: req.headers }),
    ...(req.extraOptions || {}),
    toast: req.toast,
    signal: req.signal,
  };
}
